<template>
  <div class="flex-row align-items-center">
    <CRow class="justify-content-center headimg mb-5">
      <CCol md="12" class="p-0">
        <div
          style="
            background-image: url('img/Dashboard.png');
            background-position: 50% 50%;
            background-size: cover;
            height: 200px;
          "
        >
          <h2 class="text-center text-white middle-center">
            <b
              >Detail Permohonan <br /><span class="text-success">{{
                detail_data.modules.m_nama
              }}</span></b
            >
          </h2>
        </div>
      </CCol>
    </CRow>
    <div class="container">
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard>
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h3 class="mb-0 text-center">Detail Permohonan</h3>
            </CCardHeader>
            <CCardBody>
              <CTabs>
                <CTab title="Detail Permohonan" active>
                  <CRow>
                    <div class="col-12 my-3">
                      <h3 class="mb-0">
                        Detail Permohonan {{ detail_data.kode_permohonan }}
                      </h3>
                    </div>
                    <div class="col-12">
                      <table class="w-100 table-tr-spacing">
                        <tbody>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >No. Surat Permohonan
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px">:</td>
                            <td v-if="pr_nomor_surat">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="No. Surat Permohonan"
                                v-model="pr_nomor_surat"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px">
                              <label for=""
                                ><b
                                  >Tanggal Surat <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px">:</td>
                            <td v-if="pr_tanggal">
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Tanggal Surat"
                                v-model="pr_tanggal"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px">
                              <label for=""
                                ><b
                                  >Kode Permohonan <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px">:</td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="No. Surat Permohonan"
                                v-model="detail_data.kode_permohonan"
                                readonly=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Perihal <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <textarea
                                rows="5"
                                class="form-control"
                                placeholder="Perihal"
                                readonly=""
                                v-model="pr_perihal"
                              ></textarea>
                            </td>
                          </tr>
                          <tr v-if="list_penanggung_jawab.length != 0">
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Penanggung Jawab
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <v-select
                                :options="list_penanggung_jawab"
                                label="nama_penanggung_jwb"
                                v-model="penanggung_jawab"
                                placeholder="Pilih Penanggung Jawab"
                              >
                                <template
                                  v-slot:option="value_list_penanggung_jawab"
                                >
                                  {{
                                    value_list_penanggung_jawab.nama_penanggung_jwb
                                  }}
                                  -
                                  {{
                                    value_list_penanggung_jawab.jabatan_penanggung_jwb
                                  }}
                                </template>
                                <template
                                  #selected-option="value_list_penanggung_jawab"
                                >
                                  {{
                                    value_list_penanggung_jawab.nama_penanggung_jwb
                                  }}
                                  -
                                  {{
                                    value_list_penanggung_jawab.jabatan_penanggung_jwb
                                  }}
                                </template>
                              </v-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                class="alert alert-info alert-sm"
                                v-if="list_penanggung_jawab.length != 0"
                              >
                                Nama penanggung jawab diambil dari oss, apabila
                                nama penanggung jawab tidak tersedia, mohon cek
                                aplikasi kemenkumham
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Data benih
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>Masukan data benih pada table dibawah</td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <table
                                class="table table-bordered table-sm"
                                id="table-benih"
                              >
                                <thead class="bg-sapphire">
                                  <tr>
                                    <th>Jenis Tanaman</th>
                                    <th>Nama Latin</th>
                                    <th>Hibrida/Inbrida/Komposit</th>
                                    <th>Nama Varietas</th>
                                    <!-- <th>Nama Induk</th> -->
                                    <th>Bentuk Benih</th>
                                    <th>Jumlah</th>
                                    <th>Satuan</th>
                                    <th>
                                      Nilai <span>(Total Harga dalam USD)</span>
                                    </th>
                                    <th>Kode HS</th>
                                    <td>
                                      <div
                                        class="btn btn-sm btn-success"
                                        v-on:click="addRowBenih"
                                      >
                                        <i class="fa fa-plus"></i>
                                      </div>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(value_rows_benih_raw,
                                    key_rows_benih_raw) in rows_benih_raw"
                                    :key="key_rows_benih_raw"
                                  >
                                    <td>
                                      <v-select
                                        :options="list_tanaman"
                                        label="t_nama"
                                        v-model="
                                          value_rows_benih_raw.tanaman_id
                                        "
                                        @input="
                                          changeJenisTanaman(
                                            $event,
                                            key_rows_benih_raw
                                          )
                                        "
                                        :value="$store.myValue"
                                        placeholder="Pilih Jenis Tanaman"
                                      ></v-select>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          value_rows_benih_raw.pb_nama_latin
                                        "
                                        readonly="readonly"
                                      />
                                    </td>
                                    <td>
                                      <div
                                        v-if="
                                          value_rows_benih_raw.is_select_hibrida ==
                                          true
                                        "
                                      >
                                        <v-select
                                          :options="[
                                            'Hibrida',
                                            'Inbrida',
                                            'Komposit',
                                          ]"
                                          placeholder="Pilih Hibrida/Inbrida/Komposit"
                                          v-model="
                                            value_rows_benih_raw.pb_hibrida_inbrida_komposit
                                          "
                                          :disabled="
                                            value_rows_benih_raw.is_select_hibrida !=
                                            true
                                          "
                                        ></v-select>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          value_rows_benih_raw.pb_varietas
                                        "
                                      />
                                      <!-- <v-select :options="['Varietas','Klon','Hibrida']" placeholder="Pilih Varietas Klon Hibrida" v-model="value_rows_benih_raw.pb_varietas"></v-select>	 -->
                                    </td>
                                    <!-- <td>
																			<input type="text" class="form-control" v-model="value_rows_benih_raw.pb_nama_induk">
																		</td> -->
                                    <td>
                                      <v-select
                                        :options="list_bentuk_benih"
                                        label="bb_nama"
                                        v-model="
                                          value_rows_benih_raw.bentuk_benih_id
                                        "
                                        placeholder="Pilih Bentuk Benih"
                                      ></v-select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        class="form-control"
                                        v-model="value_rows_benih_raw.pb_jumlah"
                                      />
                                    </td>
                                    <td>
                                      <v-select
                                        :options="list_satuan"
                                        label="s_nama"
                                        v-model="value_rows_benih_raw.satuan_id"
                                        placeholder="Pilih Satuan"
                                      ></v-select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        class="form-control"
                                        v-model="
                                          value_rows_benih_raw.pb_nilai_total
                                        "
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          value_rows_benih_raw.pb_kode_hs
                                        "
                                      />
                                    </td>
                                    <td>
                                      <div
                                        class="btn btn-sm btn-danger"
                                        v-on:click="
                                          deleteRow(
                                            key_rows_benih_raw,
                                            value_rows_benih_raw
                                          )
                                        "
                                      >
                                        <i class="fa fa-minus"></i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <small
                                >*) Pastikan data benih terisi lengkap</small
                              >
                              <input type="hidden" id="benihcount" value="0" />
                            </td>
                          </tr>

                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Perlakuan Fisik/Kimia
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Contoh : Fungisida, Bebas Tanah, Perendaman pestisida, Perendaman air hangat"
                                v-model="pr_perlakuan"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Nama Produsen
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Nama Produsen"
                                v-model="pr_nama_produsen"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Negara Tujuan
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <v-select
                                :filter="searchNegara"
                                :options="list_negara"
                                label="n_nama"
                                v-model="negara_pengirim"
                                placeholder="Pilih Negara Tujuan"
                              >
                                <template v-slot:option="value_list_negara">
                                  {{ value_list_negara.n_inisial }} -
                                  {{ value_list_negara.n_nama }}
                                </template>
                                <template #selected-option="value_list_negara">
                                  {{ value_list_negara.n_inisial }} -
                                  {{ value_list_negara.n_nama }}
                                </template>
                              </v-select>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Nama Penerima
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Nama Penerima"
                                v-model="pr_nama_pengirim"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Alamat Penerima
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <textarea
                                rows="5"
                                class="form-control"
                                placeholder="Alamat Penerima"
                                v-model="pr_alamat_pengirim"
                              ></textarea>
                            </td>
                          </tr>

                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Tempat Pengeluaran
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <v-select
                                :filter="searchPelabuhan"
                                :options="list_pelabuhan"
                                label="pl_nama"
                                v-model="pelabuhan"
                                placeholder="Pilih Tempat Pengeluaran"
                              >
                                <template v-slot:option="value_list_pelabuhan">
                                  {{ value_list_pelabuhan.pl_code }} -
                                  {{ value_list_pelabuhan.pl_nama }}
                                </template>
                                <template
                                  #selected-option="value_list_pelabuhan"
                                >
                                  {{ value_list_pelabuhan.pl_code }} -
                                  {{ value_list_pelabuhan.pl_nama }}
                                </template>
                              </v-select>
                            </td>
                          </tr>

                          <tr>
                            <th style="width: 250px;">
                              <label for=""
                                ><b
                                  >Tujuan Pengeluaran Benih
                                  <span class="text-danger">*</span></b
                                ></label
                              >
                            </th>
                            <td style="width: 15px;">:</td>
                            <td>
                              <input
                                type="text"
                                class="form-control text-capitalize"
                                placeholder="Contoh : Koleksi / Komersial / Sampel / Parental Produksi"
                                v-model="tujuan_pemasukan"
                                :disabled="
                                  kategori_module !=
                                  'Pengeluaran Benih Hortikultura'
                                "
                              />
                            </td>
                          </tr>
                          <template v-if="module == 'Hortikultura'">
                            <tr>
                              <th style="width: 250px">
                                <label for="">
                                  <b
                                    >Tanda Daftar Produsen/Sertifikat
                                    <span class="text-danger">*</span></b
                                  >
                                </label>
                              </th>
                              <td style="width: 15px">:</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <table
                                  class="table table-bordered table-sm"
                                  id="table-benih"
                                >
                                  <thead class="bg-sapphire">
                                    <tr>
                                      <th>Nomor Sertifikat</th>
                                      <th>Nama Perusahaan</th>
                                      <th>Ruang Lingkup</th>
                                      <th>Berlaku sampai dengan</th>
                                      <th>File <span class="text-danger">*</span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="tanda_daftar.nomor_sertifikat"
                                          placeholder="Nomor Sertifikat"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="tanda_daftar.nama_perusahaan"
                                          placeholder="Nama Perusahaan"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="tanda_daftar.ruang_lingkup"
                                          placeholder="Ruang Lingkup"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="tanda_daftar.berlaku_sampai"
                                          placeholder="Berlaku sampai dengan"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="file"
                                          class="form-control mb-0"
                                          id="file-tanda-daftar"
                                          v-on:change="inputFileTandaDaftar()"
                                        />
                                        <div
                                          class="respond-input-file float-left"
                                          id="respond-file-tanda-daftar"
                                        ></div>
                                        <small class="float-right"
                                          >*) Max File 2 MB</small
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <th style="width: 250px">
                                <label for="">
                                  <b
                                    >Informasi Tanaman
                                    <span class="text-danger">*</span></b
                                  >
                                </label>
                              </th>
                              <td style="width: 15px">:</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <table
                                  class="table table-bordered table-sm"
                                  id="table-benih"
                                >
                                  <thead class="bg-sapphire">
                                    <tr>
                                      <th>Asal Usul Tanaman</th>
                                      <th>Lokasi Pengambilan</th>
                                      <th>Nama Nursery</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="form-group form-check">
                                          <input
                                            type="checkbox"
                                            class="form-check-input"
                                            v-model="
                                              informasi_tanaman.asal_usul.penangkaran
                                                .checklist
                                            "
                                          />
                                          <label
                                            class="form-check-label"
                                            for="exampleCheck1"
                                            >Penangkaran (Budidaya)</label
                                          >
                                        </div>
                                        <div class="form-group form-check">
                                          <input
                                            type="checkbox"
                                            class="form-check-input"
                                            v-model="
                                              informasi_tanaman.asal_usul.kultur_jaringan
                                                .checklist
                                            "
                                          />
                                          <label
                                            class="form-check-label"
                                            for="exampleCheck1"
                                            >Kultur Jaringan</label
                                          >
                                        </div>
                                        <div class="form-group form-check">
                                          <input
                                            type="checkbox"
                                            class="form-check-input"
                                            v-model="
                                              informasi_tanaman.asal_usul.hutan.checklist
                                            "
                                          />
                                          <label
                                            class="form-check-label"
                                            for="exampleCheck1"
                                            >Hutan</label
                                          >
                                        </div>
                                      </td>
                                      <td>
                                        <div class="form-group">
                                          <b for="">Provinsi</b>
                                          <v-select
                                            :options="list_daerah"
                                            label="pr_nama"
                                            v-model="
                                              informasi_tanaman.lokasi_pengambilan
                                                .provinsi_selected
                                            "
                                          ></v-select>
                                        </div>

                                        <div class="form-group">
                                          <b for="">Kabupaten / Kota</b>
                                          <v-select
                                            :options="
                                              informasi_tanaman.lokasi_pengambilan
                                                .provinsi_selected.kota
                                            "
                                            label="k_nama"
                                            v-model="
                                              informasi_tanaman.lokasi_pengambilan
                                                .kota_selected
                                            "
                                          ></v-select>
                                        </div>
                                        <div class="form-group">
                                          <b for="">Alamat Lengkap</b>
                                          <textarea
                                            rows="5"
                                            class="form-control"
                                            v-model="
                                              informasi_tanaman.lokasi_pengambilan
                                                .alamat_lengkap
                                            "
                                          ></textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="informasi_tanaman.nama_nurseri"
                                          placeholder="Nama Nursery"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </CRow>
                </CTab>
                <CTab title="Dokumen Permohonan">
                  <CRow>
                    <CCol md="12" class="mt-3">
                      <CTabs variant="pills">
                        <CTab title="Dokumen Persyaratan" active>
                          <h3 class="mb-0 my-3">Dokumen Persyaratan</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px;">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 100px;">File</th>
                                <th style="width: 250px;">Keterangan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value_doc_persyaratan,
                                index_doc_persyaratan) in detail_data.permohonan
                                  .permohonan_dokumen"
                                :key="index_doc_persyaratan"
                              >
                                <td>{{ index_doc_persyaratan + 1 }}</td>
                                <td>
                                  {{
                                    value_doc_persyaratan.dokumen_permohonan
                                      .dokumen_persyaratan_permohonan.dpp_nama
                                  }}<br />
                                  <div
                                    v-if="
                                      value_doc_persyaratan
                                        .permohonan_dokumen_verifikasi.length !=
                                      0
                                    "
                                  >
                                    <div
                                      v-if="
                                        value_doc_persyaratan
                                          .permohonan_dokumen_verifikasi[0]
                                          .status == 'tolak'
                                      "
                                    >
                                      <template>
                                        <a
                                          :href="
                                            'assets-download/Surat Permohonan Pengeluaran ' +
                                            module +
                                            '.pdf'
                                          "
                                          target="_blank"
                                          v-if="
                                            value_doc_persyaratan
                                              .dokumen_permohonan
                                              .dokumen_persyaratan_permohonan
                                              .dpp_nama == 'Surat Permohonan'
                                          "
                                        >
                                          <span
                                            class="badge badge-info p-1 float-right"
                                            ><i class="fa fa-download"></i>
                                            Contoh Surat Permohonan</span
                                          >
                                        </a>
                                        <a
                                          href="assets-download/Format Proposal Pengajuan SIP Mentan SDG Tanaman.docx"
                                          v-if="
                                            value_doc_persyaratan
                                              .dokumen_permohonan
                                              .dokumen_persyaratan_permohonan
                                              .dpp_nama == 'Proposal Penelitian'
                                          "
                                        >
                                          <span
                                            class="badge badge-info p-1 float-right"
                                            ><i class="fa fa-download"></i>
                                            Format Proposal Penelitian</span
                                          >
                                        </a>
                                      </template>
                                      <input
                                        type="file"
                                        class="form-control mb-0"
                                        :id="
                                          'files-doc-' + index_doc_persyaratan
                                        "
                                        v-on:change="
                                          inputFileDocument(
                                            index_doc_persyaratan
                                          )
                                        "
                                      />
                                      <small class="float-right"
                                        >*) Max File 2 MB</small
                                      >
                                      <input
                                        type="hidden"
                                        :id="'doc-ids-' + index_doc_persyaratan"
                                        :value="value_doc_persyaratan.id"
                                      />
                                      <div
                                        class="respond-input-file float-left"
                                        :id="
                                          'docloading-' + index_doc_persyaratan
                                        "
                                      ></div>
                                      <br />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    target="_blank"
                                    class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                    v-on:click="
                                      getFile(value_doc_persyaratan.file)
                                    "
                                  >
                                    <i class="fa fa-eye"></i> Lihat
                                  </div>
                                </td>
                                <td>
                                  <div
                                    v-if="
                                      value_doc_persyaratan
                                        .permohonan_dokumen_verifikasi.length !=
                                      0
                                    "
                                  >
                                    {{
                                      value_doc_persyaratan
                                        .permohonan_dokumen_verifikasi[0]
                                        .pdv_keterangan
                                    }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </CTab>
                        <CTab title="Dokumen Proses">
                          <h3 class="mb-0 my-3">Dokumen Proses</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px;">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 100px;">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value_doc_proses,
                                index_doc_proses) in detail_data.dokumen_proses"
                                :key="index_doc_proses"
                              >
                                <td>{{ index_doc_proses + 1 }}</td>
                                <td>{{ value_doc_proses.nama_dokumen }}</td>
                                <td>
                                  <div
                                    target="_blank"
                                    class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                    v-on:click="
                                      getFileWithLink(value_doc_proses.link)
                                    "
                                  >
                                    <i class="fa fa-eye"></i> Lihat
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </CTab>
                        <CTab title="Dokumen Hasil">
                          <h3 class="mb-0 my-3">Dokumen Hasil</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px;">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 100px;">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value_doc_hasil,
                                index_doc_hasil) in detail_data.dokumen_hasil"
                                :key="index_doc_hasil"
                              >
                                <td>{{ index_doc_hasil + 1 }}</td>
                                <td>{{ value_doc_hasil.nama_dokumen }}</td>
                                <td>
                                  <div
                                    target="_blank"
                                    class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                    v-on:click="
                                      getFileWithLink(value_doc_hasil.link)
                                    "
                                  >
                                    <i class="fa fa-eye"></i> Lihat
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </CTab>
                      </CTabs>
                    </CCol>
                  </CRow>
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="12">
          <CCard class="top-radius-15">
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h3 class="mb-0 text-center">Timeline</h3>
            </CCardHeader>
            <CCardBody style="overflow: scroll;" class="p-2">
              <ul class="timeline timeline-horizontal">
                <li
                  class="timeline-item"
                  v-for="(value_timelime, index_timelime) in detail_data
                    .permohonan.permohonan_timeline"
                  :key="index_timelime"
                >
                  <template>
                    <div
                      class="timeline-badge danger"
                      v-if="value_timelime.status == 'ditolak'"
                    >
                      <i class="fa fa-times" style="margin-top: 12px;"></i>
                    </div>
                    <div class="timeline-badge success" v-else>
                      <i class="fa fa-check" style="margin-top: 12px;"></i>
                    </div>
                  </template>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <b class="timeline-title">
                        {{ value_timelime.jabatan || value_timelime.users.roles }}</b
                      >
                      <p>
                        <small class="text-muted"
                          ><i class="fa fa-clock-o"></i>
                          {{ value_timelime.pt_tanggal }}</small
                        >
                      </p>
                    </div>
                    <div class="badge badge-info">
                      {{ value_timelime.pt_status_keterangan }}
                    </div>
                    <br />
                    <div
                      class="timeline-body"
                      v-if="value_timelime.pt_keterangan"
                    >
                      <b>Catatan : </b><br />
                      <p>{{ value_timelime.pt_keterangan }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </CCardBody>
            <CCardFooter>
              <div class="alert alert-info">
                Pastikan anda memperbaiki file permohonan yang telah ditolak.
              </div>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol md="12" class="mb-3">
          <div class="row justify-content-center">
            <div class="col-4 text-center">
              <router-link
                to="/pemohon/dashboardpemohon"
                class="btn btn-warning btn-block text-white btn-hover-outline-warning btn-lg"
              >
                <i class="fa fa-chevron-left"></i>Kembali
              </router-link>
            </div>
            <div class="col-4 text-center" v-if="is_open">
              <!-- v-bind:class="{'disabled': !ajukan_ulang}"  -->
              <div
                class="btn btn-success btn-block text-white btn-hover-outline-success btn-lg"
                v-on:click="submitPermohonan('ajukan')"
              >
                <i class="fa fa-paper-plane-o"></i> Ajukan Ulang
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
const null_array = [];
export default {
  name: "DetailPermohonanPemasukanPemohon",
  data() {
    return {
      detail_data: null_array,
      data_benih: null_array,
      permohonan_status: null_array,
      rows_benih_raw: [],
      list_tanaman_kategori_sub: [],
      list_negara: [],
      list_tanaman: [],
      list_pelabuhan: [],
      list_document: [],
      permohonan_dokumen: [],
      list_bentuk_benih: [],
      list_satuan: [],

      negara_pengirim: "",
      pelabuhan: "",
      module: "",
      kategori_module: "",
      kategori_id_module: "",
      tujuan_pemasukan: "",
      tujuan_pemasukan_id: "",
      // Form input
      pr_nomor_surat: "",
      pr_tanggal: "",
      pr_perihal: "",
      tanaman_kategori_sub: "",
      pr_perlakuan: "",
      pr_cara_pengiriman: "",
      pr_nama_produsen: "",
      negara_pengirim_id: "",
      pr_nama_pengirim: "",
      pr_alamat_pengirim: "",
      pr_pekerjaan_pengirim: "",
      pr_keterangan: "-",
      session_data: JSON.parse(this.session),
      idpermohonan: this.$route.params.idpermohonan,
      count_doc_tolak: "",
      count_doc_update: 0,
      ajukan_ulang: false,
      is_open: false,
      sdg_komoditas: [],
      sdg_varietas_klon_hibrida: "",
      sdg_bentuk_benih: [],
      sdg_banyaknya: "",
      sdg_satuan: [],
      sdg_kode_hs: "",
      list_penanggung_jawab: [],
      penanggung_jawab: [],
      tanda_daftar: {
        nomor_sertifikat: null,
        nama_perusahaan: null,
        ruang_lingkup: null,
        berlaku_sampai: null,
        file: {
          nama: "",
          url: "",
        },
      },
      informasi_tanaman: {
        asal_usul: {
          penangkaran: {
            nama: "Penangkaran",
            checklist: false,
          },
          kultur_jaringan: {
            nama: "Kultur Jaringan",
            checklist: false,
          },
          hutan: {
            nama: "Hutan",
            checklist: false,
          },
        },
        lokasi_pengambilan: {
          provinsi_selected: {
            id: "",
            pr_nama: "",
          },
          kota_selected: {
            id: "",
            k_nama: "",
          },
          alamat_lengkap: "",
        },

        nama_nurseri: null,
      },
      list_daerah: [],
    };
  },
  methods: {
    getFileWithLink(files) {
      var win = window.open(files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getFile(files) {
      var win = window.open(this.apiLink + files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    inputFileDocument(keys) {
      const inputFileElement = $("#files-doc-" + keys)[0];
      if (inputFileElement.files[0]) {
        const file = inputFileElement.files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        const maxSize = 2242880; // 2 MB

        if (fileExtension !== "pdf") {
          alert("Hanya file PDF yang diperbolehkan.");
          inputFileElement.value = ""; // Reset input file jika bukan PDF
          return;
        }

        if (file.size > maxSize) {
          alert("Max File 2 MB");
          inputFileElement.value = ""; // Reset input file jika file terlalu besar
          return;
        }

        // Konfirmasi dari pengguna sebelum mengupload ulang
        if (
          this.permohonan_dokumen[keys] &&
          this.permohonan_dokumen[keys].file &&
          !confirm("File yang ada akan diganti. Anda yakin ingin melanjutkan?")
        ) {
          inputFileElement.value = ""; // Jika tidak, reset file input
          return;
        }

        $("#docloading-" + keys).html(
          '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Mohon Tunggu, Sedang Unggah Dokumen</span>'
        );

        var formData = new FormData();
        formData.append("file", file);
        formData.append("id", $("#doc-ids-" + keys).val());

        var config = {
          method: "post",
          url: this.apiLink + "api/permohonan/uploads",
          timeout: 60000,
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
          data: formData,
        };

        axios(config)
          .then((response) => {
            // if (response.data && response.data.data && response.data.data.url) {
            const urlWithFile = this.apiLink + response.data.data.url;
            $("#docloading-" + keys).html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlWithFile +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
            this.permohonan_dokumen[keys].file = urlWithFile; // Assume response contains full URL or adjust as needed
            // } else {
            //   throw new Error(
            //     "File upload failed, server did not return expected data"
            //   );
            // }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            Swal.fire({
              icon: "error",
              text: "Upload file gagal, silahkan coba lagi",
            });
            $("#docloading-" + keys).html("");
            inputFileElement.value = ""; // Reset on failure
          });

        this.count_doc_update++;
        this.permohonan_dokumen[keys] = this.permohonan_dokumen[keys]; // Ensure the document data is updated in the state
        if (this.count_doc_update >= this.count_doc_tolak) {
          this.ajukan_ulang = true;
        }
      }
    },
    inputFileTandaDaftar() {
      if ($("#file-tanda-daftar")[0].files[0]) {
        if ($("#file-tanda-daftar")[0].files[0].size < 2242880) {
          $("#respond-file-tanda-daftar").html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = this.apiLink;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#file-tanda-daftar")[0].files[0]);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response);
            urlres += response.data.data.url;
            $("#respond-file-tanda-daftar").html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    submitPermohonan(saveas) {
      // if (self.count_doc_update >= self.count_doc_tolak) {

      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon tunggu sebentar...",
        showConfirmButton: false,
      });
      var rows_benih = [];
      // cek required
      let required_form = new Promise(function (resolve, reject) {
        // cek required
        if (self.rows_benih_raw.length == 0 && saveas != "draft") {
          Swal.fire({
            icon: "error",
            title: "Pastikan Data Benih Terisi",
          });
          resolve("stop");
        }

        if (self.module == "Sumber Daya Genetik") {
          if (
            (self.pr_cara_pengiriman == "" ||
              self.pr_cara_pengiriman == null) &&
            saveas != "draft"
          ) {
            Swal.fire({
              icon: "error",
              title: "Pastikan Data Terisi Lengkap..",
            });
            resolve("stop");
          }
          $.each(self.rows_benih_raw, function (index, val) {
            if (
              // val.tanaman_id == '' || val.tanaman_id == null ||
              // val.pb_nama_latin == '' || val.pb_nama_latin == null ||
              val.pb_varietas == "" ||
              val.pb_varietas == null ||
              // val.bentuk_benih_id == '' || val.bentuk_benih_id == null ||
              val.pb_jumlah == "" ||
              val.pb_jumlah == null ||
              // val.satuan_id == '' || val.satuan_id == null ||
              val.pb_nilai_total == "" ||
              val.pb_nilai_total == null
              // val.pb_kode_hs == '' || val.pb_kode_hs == null
            ) {
              Swal.fire({
                icon: "error",
                title: "Pastikan Data Benih Terisi",
              });
              resolve("stop");
            } else {
              rows_benih.push({
                tanaman_id: "",
                pb_nama_umum: "-",
                pb_nama_latin: "",
                pb_varietas: val.pb_varietas,
                pb_nama_induk: "-",
                bentuk_benih_id: "",
                pb_jumlah: val.pb_jumlah,
                satuan_id: "",
                pb_nilai_total: val.pb_nilai_total,
                pb_kode_hs: "",
              });
            }
          });
        } else {
          if (
            (self.pr_perlakuan == "" ||
              self.pr_perlakuan == null ||
              self.pr_nama_produsen == "" ||
              self.pr_nama_produsen == null) &&
            saveas != "draft"
          ) {
            Swal.fire({
              icon: "error",
              title: "Pastikan Data Terisi Lengkap.",
            });
            resolve("stop");
          }
          $.each(self.rows_benih_raw, function (index, val) {
            if (
              val.tanaman_id == "" ||
              val.tanaman_id == null ||
              val.pb_nama_latin == "" ||
              val.pb_nama_latin == null ||
              val.pb_varietas == "" ||
              val.pb_varietas == null ||
              // val.pb_nama_induk == '' || val.pb_nama_induk == null ||

              val.bentuk_benih_id == "" ||
              val.bentuk_benih_id == null ||
              val.pb_jumlah == "" ||
              val.pb_jumlah == null ||
              val.satuan_id == "" ||
              val.satuan_id == null ||
              val.pb_nilai_total == "" ||
              val.pb_nilai_total == null ||
              val.pb_kode_hs == "" ||
              val.pb_kode_hs == null
            ) {
              Swal.fire({
                icon: "error",
                title: "Pastikan Data Benih Terisi",
              });
              resolve("stop");
            } else {
              rows_benih.push({
                tanaman_id: val.tanaman_id.id,
                pb_nama_umum: "-",
                pb_nama_latin: val.pb_nama_latin,
                pb_varietas: val.pb_varietas,
                pb_nama_induk: val.pb_nama_induk,
                bentuk_benih_id: val.bentuk_benih_id.id,
                pb_jumlah: val.pb_jumlah,
                satuan_id: val.satuan_id.id,
                pb_nilai_total: val.pb_nilai_total,
                pb_kode_hs: val.pb_kode_hs,
                pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
              });
            }
          });
        }
        if (
          // self.pr_nomor_surat == '' || self.pr_nomor_surat == null ||
          // self.pr_tanggal == '' || self.pr_tanggal == null ||
          (self.negara_pengirim == "" ||
            self.negara_pengirim == null ||
            self.pr_nama_pengirim == "" ||
            self.pr_nama_pengirim == null ||
            self.pr_alamat_pengirim == "" ||
            self.pr_alamat_pengirim == null ||
            self.pelabuhan == "" ||
            self.pelabuhan == null ||
            self.tujuan_pemasukan == "" ||
            self.tujuan_pemasukan == null) &&
          // self.penanggung_jawab == '' || self.penanggung_jawab == null
          saveas != "draft"
        ) {
          Swal.fire({
            icon: "error",
            title: "Pastikan Data Terisi Lengkap",
          });
          resolve("stop");
        }
        // $.each(self.permohonan_dokumen, function(index, val) {
        // 	if (
        // 		(
        // 			val.file == '' || val.file == null
        // 		) && saveas != 'draft' && val.is_required
        // 	) {
        // 		// alert(val.nama_dpp);
        // 		Swal.fire({
        // 			icon: 'error',
        // 			title: 'Pastikan Data Dokumen Terunggah',
        // 		});
        // 		resolve("stop");
        // 	}
        // });
        resolve("next");
      });

      // Hasil req
      required_form.then(function (response) {
        if (response == "stop") {
          return false;
        }
        // return false;
        var FormData = require("form-data");
        var data = new FormData();
        data.append("module_id", self.kategori_id_module);
        data.append("pr_kategori", self.module);
        data.append("pr_perihal", self.pr_perihal);
        data.append("nib", self.session_data.nib);
        data.append("pr_nomor_surat", self.pr_nomor_surat);
        data.append("pr_tanggal", self.pr_tanggal);
        data.append("module_categories_id", self.kategori_id_module);
        data.append("pr_perlakuan", self.pr_perlakuan);
        data.append("pr_nama_produsen", self.pr_nama_produsen);
        data.append("negara_pengirim_id", self.negara_pengirim.id);
        data.append("pr_nama_pengirim", self.pr_nama_pengirim);
        data.append("pr_alamat_pengirim", self.pr_alamat_pengirim);
        data.append("pr_pekerjaan_pengirim", self.pr_pekerjaan_pengirim);
        data.append("pelabuhan_id", self.pelabuhan.id);
        data.append("tujuan_penggunaan_benih_id", self.tujuan_pemasukan_id);
        data.append("tujuan_penggunaan_benih_nama", self.tujuan_pemasukan);
        data.append("pr_keterangan", self.pr_keterangan);
        data.append("pr_cara_pengiriman", self.pr_cara_pengiriman);
        data.append("status", saveas);
        data.append("permohonan_benih", JSON.stringify(rows_benih));
        data.append("id", self.idpermohonan);
        data.append("is_pemasukan", 0);
        if (self.tanaman_kategori_sub?.id) {
          data.append("tanaman_kategori_sub_id", self.tanaman_kategori_sub?.id);
        }

        if (self.sdg_komoditas?.id) {
          data.append("sdg_komoditas_id", self.sdg_komoditas?.id);
        }
        if (self.sdg_bentuk_benih?.id) {
          data.append("sdg_bentuk_benih_id", self.sdg_bentuk_benih?.id);
        }
        if (self.sdg_satuan?.id) {
          data.append("sdg_satuan_id", self.sdg_satuan?.id);
        }
        data.append(
          "sdg_varietas_klon_hibrida",
          self.sdg_varietas_klon_hibrida
        );
        data.append("sdg_banyaknya", self.sdg_banyaknya);
        data.append("sdg_kode_hs", self.sdg_kode_hs);
        data.append(
          "json_penanggung_jawab",
          JSON.stringify(self.penanggung_jawab)
        );
        data.append("tanda_daftar", JSON.stringify(self.tanda_daftar));
        data.append(
          "informasi_tanaman",
          JSON.stringify(self.informasi_tanaman)
        );
        // console.log(self.kategori_id_module);
        var config = {
          method: "post",
          url: self.apiLink + "api/permohonan/update",
          headers: {
            Authorization: "Bearer " + self.access_token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            var res_ajukan = response.data;
            var res_ajukan_data = res_ajukan.data;
            // console.log(res_ajukan);
            if (res_ajukan.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                allowOutsideClick: false,
                text: res_ajukan.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman permohonan masa proses",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "DashboardPemohon" });
                }
              });
            }
          })
          .catch(error => {
            if (error) {
              if (error?.response) {
                var htmlerror = "";
                if (
                  typeof error.response.data.data.error === "string" ||
                  error.response.data.data.error instanceof String
                ) {
                  var htmlerror = error.response.data.data.error;
                } else {
                  $.each(error.response.data.data.error, function (index, val) {
                    htmlerror += val[0] + "<br>";
                  });
                }

                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  html: htmlerror,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Gagal, Silahkan cek koneksi anda !",
                });
              }
            }
          });
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    searchNegara(options, search) {
      const fuse = new Fuse(options, {
        keys: ["n_inisial", "n_nama"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    searchPelabuhan(options, search) {
      const fuse = new Fuse(options, {
        keys: ["pl_code", "pl_nama"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    getFile(files) {
      var win = window.open(this.apiLink + files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    changeJenisTanaman(id_tanaman, key_row_tanaman) {
      // var tanaman = this.list_tanaman[id_tanaman.id];

      if (id_tanaman == null) {
        this.rows_benih_raw[key_row_tanaman].pb_nama_latin = "";
        return false;
      }
      // this.rows_benih_raw[key_row_tanaman].pb_nama_latin = id_tanaman.t_nama_latin;

      // var tanaman = this.list_tanaman.find(tanamans => {
      // 	if (tanamans.id === id_tanaman.id) {
      // 		return tanamans
      // 	}
      // });
      this.rows_benih_raw[key_row_tanaman].pb_nama_latin =
        id_tanaman.t_nama_latin;
      this.rows_benih_raw[key_row_tanaman].is_select_hibrida =
        id_tanaman.is_select_hibrida;
      this.rows_benih_raw[key_row_tanaman].pb_hibrida_inbrida_komposit = "";
      // console.log(id_tanaman)
    },
    addRowBenih() {
      this.rows_benih_raw.push({
        tanaman_id: "",
        pb_nama_umum: "-",
        pb_nama_latin: "",
        pb_varietas: "",
        pb_nama_induk: "",
        bentuk_benih_id: "",
        pb_hibrida_inbrida_komposit: "",
        pb_jumlah: "",
        satuan_id: "",
        pb_nilai_total: "",
        pb_kode_hs: "",
        is_select_hibrida: false,
        is_aropt: true,
      });
    },
    deleteRow(index, rows_benih_raw_at) {
      var idx = this.rows_benih_raw.indexOf(rows_benih_raw_at);
      if (idx > -1) {
        this.rows_benih_raw.splice(idx, 1);
      }
    },
  },
  mounted() {
    $(".select2").select2({
      placeholder: "-- Pilih --",
    });
  },
  created() {
    axios
      .get(this.apiLink + "api/permohonan/detail", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_permohonan = response.data;
        var res_permohonan_data = res_permohonan.data;
        if (res_permohonan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan.data.message,
          });
        } else {
          this.detail_data = res_permohonan_data;
          // console.log(res_permohonan_data);
          this.data_benih = res_permohonan_data.permohonan_benih;
          // console.log(res_permohonan_data);
          this.detail_data = res_permohonan_data;
          this.data_benih = res_permohonan_data.permohonan_benih;
          this.pr_nomor_surat = res_permohonan_data.permohonan.pr_nomor_surat;
          this.pr_tanggal = res_permohonan_data.permohonan.pr_tanggal;
          this.pr_perihal = res_permohonan_data.permohonan.pr_perihal;
          this.pr_perlakuan = res_permohonan_data.permohonan.pr_perlakuan;
          this.pr_nama_produsen =
            res_permohonan_data.permohonan.pr_nama_produsen;
          this.pr_nama_pengirim =
            res_permohonan_data.permohonan.pr_nama_pengirim;
          this.pr_alamat_pengirim =
            res_permohonan_data.permohonan.pr_alamat_pengirim;
          this.pr_pekerjaan_pengirim =
            res_permohonan_data.permohonan.pr_pekerjaan_pengirim;
          this.pr_cara_pengiriman =
            res_permohonan_data.permohonan.pr_cara_pengiriman;
          this.pr_keterangan = res_permohonan_data.permohonan.pr_keterangan;

          this.sdg_komoditas = res_permohonan_data.permohonan.sdg_komoditas;
          this.sdg_varietas_klon_hibrida =
            res_permohonan_data.permohonan.sdg_varietas_klon_hibrida;
          this.sdg_bentuk_benih =
            res_permohonan_data.permohonan.sdg_bentuk_benih;
          this.sdg_banyaknya = res_permohonan_data.permohonan.sdg_banyaknya;
          this.sdg_satuan = res_permohonan_data.permohonan.sdg_satuan;
          this.sdg_kode_hs = res_permohonan_data.permohonan.sdg_kode_hs;

          this.negara_pengirim = res_permohonan_data.negara_pengirim;
          this.pelabuhan = res_permohonan_data.pelabuhan;
          if (res_permohonan_data.tujuan_penggunaan_benih?.id) {
            this.tujuan_pemasukan_id =
              res_permohonan_data.tujuan_penggunaan_benih.id;
          }

          this.tujuan_pemasukan =
            res_permohonan_data.permohonan.tujuan_penggunaan_benih_nama;
          this.kategori_id_module = res_permohonan_data.permohonan.modules_id;
          this.module = res_permohonan_data.permohonan.pr_kategori;
          this.kategori_module = res_permohonan_data.permohonan.pr_perihal;
          this.penanggung_jawab = JSON.parse(
            res_permohonan_data.permohonan.json_penanggung_jawab
          );
          if(res_permohonan_data.permohonan.pr_tanda_daftar) {
            this.tanda_daftar = JSON.parse(
              res_permohonan_data.permohonan.pr_tanda_daftar
            );
          }
          this.informasi_tanaman = JSON.parse(
            res_permohonan_data.permohonan.pr_informasi_tanaman
          );
          if (res_permohonan_data.permohonan.tanaman_kategori_sub != null) {
            this.tanaman_kategori_sub =
              res_permohonan_data.permohonan.tanaman_kategori_sub;
          }
          var benihdinamis = [];
          $.each(res_permohonan_data.permohonan.permohonan_benih, function (
            index,
            val
          ) {
            benihdinamis.push({
              tanaman_id: val.tanaman,
              pb_nama_umum: "-",
              pb_nama_latin: val.pb_nama_latin,
              pb_varietas: val.pb_varietas,
              pb_nama_induk: val.pb_nama_induk,
              bentuk_benih_id: val.bentuk_benih,
              pb_jumlah: val.pb_jumlah,
              satuan_id: val.satuan,
              pb_nilai_total: val.pb_nilai_total,
              pb_kode_hs: val.pb_kode_hs,
              is_aropt: val.is_aropt,
              pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
              is_select_hibrida: val?.tanaman?.is_select_hibrida,
            });
          });
          this.rows_benih_raw = benihdinamis;
          var clear_document = [];
          var jumlah_tolak = 0;
          $.each(res_permohonan_data.permohonan.permohonan_dokumen, function (
            index,
            val
          ) {
            // console.log(val);
            clear_document.push({
              id: val.id,
              id_dpp: val.dokumen_permohonan.dokumen_persyaratan_permohonan.id,
              nama_dpp:
                val.dokumen_permohonan.dokumen_persyaratan_permohonan.dpp_nama,
              template_dpp_link: "",
              text_dpp_info: "",
              status_verifikasi: val.permohonan_dokumen_verifikasi[0],
              preview_link: val.file,
            });
            if (val.permohonan_dokumen_verifikasi[0]?.status == "tolak") {
              jumlah_tolak++;
            }
          });
          this.count_doc_tolak = jumlah_tolak;
          this.list_document = clear_document;
          // Get Master Tanaman
          axios
            .get(this.apiLink + "api/master/tanaman", {
              params: {
                tanaman_kategori_id:
                  res_permohonan_data.tujuan_penggunaan_benih
                    .tanaman_kategori_id,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then(response => {
              var res_tanaman = response.data;
              var res_tanaman_data = res_tanaman.data;
              if (res_tanaman.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_tanaman.data.message,
                });
              } else {
                var raw_tanaman = res_tanaman_data.master;
                var clear_tanaman = [];
                var nama_module = this.module;
                $.each(raw_tanaman, function (index_kategori, val_kategori) {
                  $.each(val_kategori.tanaman_kategori_sub, function (
                    index_sub_kategori,
                    val_sub_kategori
                  ) {
                    var sub_kategori = val_sub_kategori.tanaman_kategori_nama;
                    if (nama_module != "Sumber Daya Genetik") {
                      if (
                        sub_kategori.toLowerCase() == nama_module.toLowerCase()
                      ) {
                        $.each(val_sub_kategori.tanaman, function (
                          index_tanaman,
                          val_tanaman
                        ) {
                          clear_tanaman[val_tanaman.id] =
                            val_sub_kategori.tanaman[index_tanaman];
                        });
                      }
                    } else {
                      $.each(val_sub_kategori.tanaman, function (
                        index_tanaman,
                        val_tanaman
                      ) {
                        clear_tanaman[val_tanaman.id] =
                          val_sub_kategori.tanaman[index_tanaman];
                      });
                    }
                  });
                });
                var clear_tanaman = clear_tanaman.filter(function (el) {
                  return el != null;
                });
                this.list_tanaman = clear_tanaman;
                Swal.close();
              }
            });
        }
      });
    // Get Process Status
    axios
      .get(this.apiLink + "api/permohonan/status", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_permohonan_status = response.data;
        var res_permohonan_status_data = res_permohonan_status.data;
        if (res_permohonan_status.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan_status.data.message,
          });
        } else {
          this.permohonan_status = res_permohonan_status_data;
        }
      });
    // console.log(this.session_data);
    // Get Sub Jenis Tanaman
    axios
      .get(this.apiLink + "api/master/tanaman_kategori_sub", {
        params: {
          tanaman_kategori_id: 2,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_tanaman_kategori_sub = response.data;
        var res_tanaman_kategori_sub_data = res_tanaman_kategori_sub.data;
        if (res_tanaman_kategori_sub.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_tanaman_kategori_sub.data.message,
          });
        } else {
          this.list_tanaman_kategori_sub = res_tanaman_kategori_sub_data.master;
        }
      });
    // Get Master Negara
    axios
      .get(this.apiLink + "api/master/negara", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_negara = response.data;
        var res_negara_data = res_negara.data;
        if (res_negara.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_negara.data.message,
          });
        } else {
          this.list_negara = res_negara_data.master;
        }
      });
    // Get Master Pelabuhan
    axios
      .get(this.apiLink + "api/master/pelabuhan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_pelabuhan = response.data;
        var res_pelabuhan_data = res_pelabuhan.data;
        if (res_pelabuhan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_pelabuhan.data.message,
          });
        } else {
          this.list_pelabuhan = res_pelabuhan_data.master;
        }
      });

    axios
      .get(this.apiLink + "api/master/bentuk_benih", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_bentuk_benih = response.data;
        var res_bentuk_benih_data = res_bentuk_benih.data;
        if (res_bentuk_benih.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_bentuk_benih.data.message,
          });
        } else {
          this.list_bentuk_benih = res_bentuk_benih_data.master;
        }
      });
    axios
      .get(this.apiLink + "api/master/satuan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_satuan = response.data;
        var res_satuan_data = res_satuan.data;
        if (res_satuan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_satuan.data.message,
          });
        } else {
          this.list_satuan = res_satuan_data.master;
        }
      });
    let self = this;
    axios
      .get(this.apiLink + "api/crud/konfigurasi", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        var res_konfigurasi = response.data;
        var res_konfigurasi_data = res_konfigurasi.data[0];
        // console.log(res_konfigurasi_data);
        if (res_konfigurasi.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_konfigurasi.data.message,
          });
        } else {
          var open_apli = res_konfigurasi_data.find(options => {
            if (options.id === 1) {
              return options;
            }
          });
          if (open_apli.k_aksi != "open") {
            self.is_open = true;
          }
        }
      });
    var json_perusahaan = JSON.parse(this.session_data.perusahaan.p_json);
    if (json_perusahaan) {
      this.list_penanggung_jawab =
        json_perusahaan.responinqueryNIB.dataNIB.penanggung_jwb;
      console.log(this.list_penanggung_jawab);
    }
  },
};
</script>
